import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import styles from "./blog.module.css";
import Layout from "../components/layout";
import ArticlePreview from "../components/article-preview";

class ContributeIndex extends React.Component {
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title");
    const posts = get(this, "props.data.allContentfulBlogPost.edges");

    return (
      <Layout location={this.props.location}>
        <div style={{ background: "#fff" }}>
          <Helmet title={siteTitle} />
          <div className={styles.hero}>Help This Garden Grow!</div>
          <div className="wrapper">
            <section className="contributions">
              <p>
                The DogZenGarden service will always remain free to use and
                without advertisements. The goal when creating this app was to
                reliving anxiety in my own dog, Boone who was suffering greatly
                during intense thunderstorms and to help other dog and dog
                owners suffering with the same problem. Donations from our users
                allow for the expansion of The DogZenGarden, allowing me to
                spend more time developing features, collecting audio, and
                fixing issue. Please follow the link below to donate. Thank you
                very much, every dollar is greatly appreciated.
              </p>
              <a href="https://www.patreon.com/dogzengarden">
                <button className="btn">Donate</button>
              </a>
            </section>
            <h2 className="section-headline">Recent articles</h2>
            <ul className="article-list">
              {posts.map(({ node }) => {
                return (
                  <li key={node.slug}>
                    <ArticlePreview article={node} />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query ContributeIndexQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
            file {
              url
              fileName
              contentType
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

export default ContributeIndex;
